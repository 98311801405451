<template>
  <div>
    <div class="top-menu-visible">
      <a
        class="app-menu-back"
        @click="
          $router.go(-1);
          isOpen = false;
        "
        ><i class="fas fa-chevron-left"></i
      ></a>
    </div>
    <div class="guest-view"></div>
    <div class="container login-wrapper">
      <div class="logo-container">
        <img :src="require('../assets/app_icon/Logo_BM_pion.png')"/>
      </div>
      <div class="guest-input-container container">
        <ValidationObserver ref="observer" tag="form" >
          <form  @submit.prevent="" novalidate>
              <ValidationProvider :rules="{required: true}" v-slot="{errors, classes}">
                <input type="text" class="form-input w-100 control" :class="classes"
                  placeholder="Podaj swoje imie i nazwisko" v-model="username"/>
                <span class="form-error-info text-center">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider :rules="{required: true, email: true}" v-slot="{errors, classes}">
                <input type="email" class="form-input w-100 control" :class="classes"
                  placeholder="Podaj swój email" v-model="useremail"/>
                <span class="form-error-info text-center">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider :rules="{required: true, min: true}" v-slot="{errors, classes}">
                <input type="password" class="form-input w-100 control" :class="classes"
                  placeholder="Podaj hasło" v-model="password"/>
                <span class="form-error-info text-center" :class="{'text-white': (6 > password.length && !errors[0])}">{{ (6 > password.length && !errors[0])? 'min.6 znaków' : errors[0] }}</span>
            </ValidationProvider>
            <button type="submit" @click="submit()"  class="btn-white-full w-100 mt-3">Zarejestruj</button>
            <button v-if="platform !== 'ios'" class="btn-white-border mt-3 w-100" @click="loginWithFacebook()">Facebook</button>

          </form>
        </ValidationObserver>
         <div class="text-danger mt-5 text-center">{{errorMessage}}
          <div v-if="errorMessage.length"><router-link class="text-white" to="/logowanie/email">Zaloguj się</router-link></div>
         </div>
      </div>
      <div class="guest-link-container">
       <!-- <div class=""><a href="#">Wejdź przez Facebok - będzie szybciej</a></div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, setInteractionMode, extend } from 'vee-validate'
import { required, email, min } from 'vee-validate/dist/rules'
setInteractionMode('eager')
// import { load } from 'recaptcha-v3'

extend('required', {
  ...required,
  message: 'Pole jest obowiązkowe'
})
extend('min', {
  ...min,
  message: 'Pole musi zawierać min.6 znaków'
})

extend('min', value => {
  return value.length >= 6
})

extend('email', {
  ...email,
  message: 'Niepoprawny format adresu email'
})

export default {
  name: 'Register',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      username: '',
      useremail: '',
      password: '',
      getResponse: '',
      errorMessage: '',
      platform: window.platform
    }
  },
  methods: {
    async submit () {
      const valid = await this.$refs.observer.validate()
      if (valid === true) {
        this.register()
      }
    },
    loginWithFacebook () {
      window.open('https://www.facebook.com/v5.0/dialog/oauth?client_id=1651672578279249&state=c390d21215195b2e18a7e50c8f8d5e48&response_type=code&redirect_uri=https%3A%2F%2F' + this.appUrl + '%2Ffacebook-return&scope=email')
    },
    async register () {
      this.errorMessage = ''
      const getData = `username=${this.username}&email=${this.useremail}&password=${this.password}`
      this.$https('/register', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(res => {
        if (res.data.response.error) {
          this.errorMessage = 'Istnieje już konto o podanym adresie e-mail'
        } else {
          const apiToken = res.data.response.token
          this.saveToken(apiToken)
          this.$router.push('/konto/stworz-profil')
        }
      }).catch(error => {
        console.log('', error)
      })
    }
  },
  created () {
    // load('<site key>', {
    //   useRecaptchaNet: true,
    //   autoHideBadge: false
    // }).then((recaptcha) => {

    // })
    // const recaptcha = this.$recaptchaInstance

    // recaptcha.showBadge()
  }
}
</script>
<style lang="scss" scoped>
  .guest-input-container {
    position: absolute;
    top: 50%;
    color: #fff;

    input {
      border: none;
      border-bottom: 1px solid #fff;
      color: #fff;
      text-align: center;
      &::placeholder {
        color: #fff;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      border-bottom: 1px solid #fff;
      -webkit-text-fill-color: #fff;
      -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.5) inset;
      box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.5) inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  .guest-link-container {
    position: absolute;
    bottom: 5%;
    font-size: 14px;

        a {
      color: #fff;
      text-decoration: underline;
      text-align: center;
    }
  }

  .form-error-info {
    display: block;
    font-size: 11px;
    margin-top: 5px;
      &-white {
        color: #fff;
      }
  }
  .control {
      &.invalid {
    border-bottom: 1px solid red;
  }

  &.valid {
    border-color: #fff;
  }
}
@media screen and (max-width: 320px) {
    .guest-input-container {
    position: absolute;
    top: 35%;
    color: #fff;
    }
  .logo-container {
  position: absolute;
  top: 10%;
}
}

@media screen and (max-height: 750px) {
    .guest-input-container {
    position: absolute;
    top: 35%;
    color: #fff;
    }
  .logo-container {
  position: absolute;
  top: 10%;
}
}
</style>
