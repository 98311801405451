var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"top-menu-visible"},[_c('a',{staticClass:"app-menu-back",on:{"click":function($event){_vm.$router.go(-1);
        _vm.isOpen = false;}}},[_c('i',{staticClass:"fas fa-chevron-left"})])]),_c('div',{staticClass:"guest-view"}),_c('div',{staticClass:"container login-wrapper"},[_c('div',{staticClass:"logo-container"},[_c('img',{attrs:{"src":require('../assets/app_icon/Logo_BM_pion.png')}})]),_c('div',{staticClass:"guest-input-container container"},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('ValidationProvider',{attrs:{"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-input w-100 control",class:classes,attrs:{"type":"text","placeholder":"Podaj swoje imie i nazwisko"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('span',{staticClass:"form-error-info text-center"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"rules":{required: true, email: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.useremail),expression:"useremail"}],staticClass:"form-input w-100 control",class:classes,attrs:{"type":"email","placeholder":"Podaj swój email"},domProps:{"value":(_vm.useremail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.useremail=$event.target.value}}}),_c('span',{staticClass:"form-error-info text-center"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"rules":{required: true, min: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-input w-100 control",class:classes,attrs:{"type":"password","placeholder":"Podaj hasło"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"form-error-info text-center",class:{'text-white': (6 > _vm.password.length && !errors[0])}},[_vm._v(_vm._s((6 > _vm.password.length && !errors[0])? 'min.6 znaków' : errors[0]))])]}}])}),_c('button',{staticClass:"btn-white-full w-100 mt-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Zarejestruj")]),(_vm.platform !== 'ios')?_c('button',{staticClass:"btn-white-border mt-3 w-100",on:{"click":function($event){return _vm.loginWithFacebook()}}},[_vm._v("Facebook")]):_vm._e()],1)]),_c('div',{staticClass:"text-danger mt-5 text-center"},[_vm._v(_vm._s(_vm.errorMessage)+" "),(_vm.errorMessage.length)?_c('div',[_c('router-link',{staticClass:"text-white",attrs:{"to":"/logowanie/email"}},[_vm._v("Zaloguj się")])],1):_vm._e()])],1),_c('div',{staticClass:"guest-link-container"})])])}
var staticRenderFns = []

export { render, staticRenderFns }